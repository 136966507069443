
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
  },
})
export default class StatsCard extends Vue {
  @Prop({ required: true }) cardData!: {
    labels: string[],
    data: number[],
    colors: string[],
    name: string,
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 65,
  };

   randomBackground() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  chartData = {
    labels: this.cardData.labels,
    datasets: [{
      backgroundColor: this.cardData.colors,
      hoverBackgroundColor: this.cardData.colors,
      borderColor: this.cardData.colors,
      hoverBorderColor: '#FFFFFF',
      hoverBorderWidth: 3,
      borderWidth: 1,
      borderAlign: 'inner',
      data: this.cardData.data,
    }],
  };
}
